import apiConfig from "../config/_config";
import Cookies from 'js-cookie';
export async function loginUser(username, password) {
    const loginApiUrl = `${apiConfig}/authentication/login`;
    const userInfoApiUrl =`${apiConfig}/user/get`;
    const loginData = {
        username: username,
        password: password,
    };

    try {
        const loginResponse = await fetch(loginApiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginData),
        });

        if (!loginResponse.ok) {
            throw new Error('Login request failed with status ' + loginResponse.status);
        }
        else{
        const loginResult = await loginResponse.json();
        Cookies.set('Token',loginResult.message.idToken, {
            secure: true});      
        const idToken = loginResult.message.idToken; 
        const userInfoResponse = await fetch(userInfoApiUrl, {
            method: 'GET',
            headers: {
                'Authorization': idToken,
            },
        });

        if (!userInfoResponse.ok) {
            throw new Error(`User info request failed with status ${userInfoResponse.status}`);
        }

        const userInfo = await userInfoResponse.json();


        localStorage.setItem('client', userInfo.message.client);
        localStorage.setItem('role', userInfo.message.role);

        return {"userInfo" : userInfo , 'idToken' : idToken};
    }
    } catch (error) {
        throw new Error(error);
        
    }
}
