/** @format */

import React, { useCallback, useEffect, useState } from "react";
import { fetchDocuments } from "../../../API/Docs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  faArrowLeft,
  faArrowRight,
  faDownload,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import process from "process";
import { getPDFview } from "../../../API/PDFView";
import * as configStatus from "../../../config/config.status";
import Cookies from "js-cookie";
const DocumentTable = ({ instanceId, instanceName, onBack }) => {
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortByByName, setSortByByName] = useState(true);
  const [sortByById, setSortByById] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [filterValue, setFilterValue] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const documentsPerPage = 10;
  const [sortByDateAscending, setSortByDateAscending] = useState(false);
  const [sortByNameAscending, setSortByNameAscending] = useState(true);
  const [sortByStatusAscending, setSortByStatusAscending] = useState(true);
  const idToken = Cookies.get('Token');

  const userRole = localStorage.getItem("role");
  const shouldEnableDWLDCSV =
    process.env.REACT_APP_DASHBOARD_DWLD_CSV === "true" ||
    userRole === process.env.REACT_APP_ROLE_ADMIN;
  const shouldEnableDWLDJdata =
    process.env.REACT_APP_DASHBOARD_DWLD_JDATA === "true" ||
    userRole === process.env.REACT_APP_ROLE_ADMIN;
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      try {
        const documentList = await fetchDocuments(instanceId, idToken);

        // Formater la date pour chaque document
        const formattedDocumentList = documentList.map((doc) => ({
          ...doc,
          LastUpdate: formatDate(doc.LastUpdate),
        }));

        const uniqueStatuses = [
          ...new Set(formattedDocumentList.map((doc) => doc.Status)),
        ];

        setDocuments(formattedDocumentList);
        setUniqueStatuses(uniqueStatuses);
        setIsLoading(false);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des données de l'API",
          error
        );
        setIsLoading(false);
      }
    }

    fetchData();
  }, [instanceId, idToken]);


  const generateAndDownloadPDF = async (DocId, InstanceId, modelId, token) => {
    try {
      const response = await getPDFview(DocId, InstanceId, modelId,token);
        if (response.message.PresignedURL ) {
        window.open(response.message.PresignedURL, '_blank');
      } else {
        console.error("Lien invalide : " + response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  


  const applyFilters = useCallback((data) => {
    const filteredData = data.filter((document) => {
      const dateMatch =
        !selectedDate || document.LastUpdate.includes(selectedDate);
      const statusMatch =
        selectedStatus === "All" || document.Status === selectedStatus;

      const nameA = document.FileName ? document.FileName.toLowerCase() : "";
      const nameB = filterValue ? filterValue.toLowerCase() : "";
      const idA = document.DocId ? document.DocId.toLowerCase() : "";
      const idB = filterValue ? filterValue.toLowerCase() : "";

      let nameFilterMatch = false;
      let idFilterMatch = false;

      if (sortByByName) {
        nameFilterMatch = nameA.includes(nameB);
      } else if (sortByById) {
        idFilterMatch = idA.startsWith(idB) || idA === idB;
      }

      return (
        (dateMatch || !selectedDate) &&
        (statusMatch || selectedStatus === "All") &&
        ((sortByByName && nameFilterMatch) ||
          (sortByById && idFilterMatch) ||
          (!filterValue && !sortByByName && !sortByById))
      );
    });

    return filteredData;
  },[selectedStatus, selectedDate, filterValue, sortByByName, sortByById]);

  useEffect(() => {
    const sortedData = applyFilters(documents);
    setFilteredDocuments(sortedData);
    setCurrentPage(1);
  }, [applyFilters, documents]);
  
  const getStatusClass = (status) => {
    const statusMappings = configStatus.statusDocMappings;

    if (statusMappings.hasOwnProperty(status)) {
      return statusMappings[status];
    } else {
      return "bg-info";
    }
  };

  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  const currentDocuments = filteredDocuments.slice(
    indexOfFirstDocument,
    indexOfLastDocument
  );
  const paginate = (pageNumber) => {
    const nextPageIndex = pageNumber - 1;
    const startIndex = nextPageIndex * documentsPerPage;
    const endIndex = startIndex + documentsPerPage;
    const hasNextPageData = documents.slice(startIndex, endIndex).length > 0;

    if (hasNextPageData) {
      setCurrentPage(pageNumber);
    }
  };
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedDate(selectedDate);
  };

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setSelectedStatus(selectedStatus);
    const filteredData = applyFilters(documents);
    setFilteredDocuments(filteredData);
  };
  
  function generateCSVDataInstance(documents) {
    const data = [];

    data.push(["Date", "Nom du document", "Statut"]);

    documents.forEach((document) => {
      data.push([document.LastUpdate, document.FileName, document.Status]);
    });

    return data;
  }

  // Gestionnaire d'événement pour le tri par date
  const toggleSortByDate = () => {
    setSortByDateAscending(!sortByDateAscending);

    setSortByStatusAscending(true);
    // Tri des données en fonction de l'ordre actuel
    const sortedData = applyFilters(documents).sort((a, b) => {
      let comparisonResult = 0;

      if (sortByStatusAscending) {
        const statusComparison = a.Status.localeCompare(b.Status);
        if (statusComparison !== 0) {
          comparisonResult = statusComparison;
        }
      }
      if (sortByDateAscending) {
        // Tri par date croissante
        const dateA = new Date(
          parseInt(a.LastUpdate.substr(6, 4)),
          parseInt(a.LastUpdate.substr(3, 2)) - 1,
          parseInt(a.LastUpdate.substr(0, 2))
        );
        const dateB = new Date(
          parseInt(b.LastUpdate.substr(6, 4)),
          parseInt(b.LastUpdate.substr(3, 2)) - 1,
          parseInt(b.LastUpdate.substr(0, 2))
        );

        comparisonResult = dateA - dateB;
      } else {
        // Tri par date décroissante
        const dateA = new Date(
          parseInt(a.LastUpdate.substr(6, 4)),
          parseInt(a.LastUpdate.substr(3, 2)) - 1,
          parseInt(a.LastUpdate.substr(0, 2))
        );
        const dateB = new Date(
          parseInt(b.LastUpdate.substr(6, 4)),
          parseInt(b.LastUpdate.substr(3, 2)) - 1,
          parseInt(b.LastUpdate.substr(0, 2))
        );

        comparisonResult = dateB - dateA;
      }

      return comparisonResult;
    });
    setFilteredDocuments(sortedData);
    setCurrentPage(1);
  };

  const toggleSortByName = () => {
    setSortByNameAscending(!sortByNameAscending);
    setSortByDateAscending(false);
    setSortByStatusAscending(false);

    // Sort the data by name
    const sortedDataByName = applyFilters(documents).sort((a, b) => {
      let comparisonResult = 0;

      if (sortByNameAscending) {
        // Sort by name A to Z
        const nameA = a.FileName ? a.FileName.toLowerCase() : "";
        const nameB = b.FileName ? b.FileName.toLowerCase() : "";
        comparisonResult = nameA.localeCompare(nameB);
      } else {
        // Sort by name Z to A
        const nameA = a.FileName ? a.FileName.toLowerCase() : "";
        const nameB = b.FileName ? b.FileName.toLowerCase() : "";
        comparisonResult = nameB.localeCompare(nameA);
      }

      return comparisonResult;
    });

    setFilteredDocuments(sortedDataByName);
    setCurrentPage(1);
  };

  const toggleSortByStatus = () => {
    setSortByStatusAscending(!sortByStatusAscending);
    setSortByDateAscending(false);
    setSortByNameAscending(true);
    const sortedDataByStatus = applyFilters(documents).sort((a, b) => {
      let comparisonResult = 0;

      if (sortByStatusAscending) {
        const statusComparison = a.Status.localeCompare(b.Status);
        if (statusComparison !== 0) {
          comparisonResult = statusComparison;
        }
      }

      return comparisonResult;
    });

    setFilteredDocuments(sortedDataByStatus);
    setCurrentPage(1);
  };
  const formatDate = (dateStr) => {
    const year = dateStr.substr(0, 4);
    const month = dateStr.substr(4, 2);
    const day = dateStr.substr(6, 2);
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <div className='header'>
        <h3>
          {t("docs")} {instanceName}/
        </h3>
        <br />
        <h6 style={{ marginTop: "-20px", justifyContent: "center" }}>
          ID : {instanceId}
        </h6>
      </div>
      <div className='content'>
        <div>
          <p>{t("text-operations-P1")}</p>
          <p>{t("text-operations-P2")}</p>
        </div>
      </div>
      <div className='table-container' style={{ minHeight: "400px" }}>
        <div className='flex-container'>
          <div className='fixed-width'>
            <input
              style={{
                boxSizing: "border-box",
                width: "100%",
                height: "30px",
                marginBottom: "10px",
                borderRadius: "3px",
                border: "1px solid #3c6382",
                borderColor: "#3c6382",
                color: "#3c6382",
                margin: "0",
              }}
              type='text'
              placeholder={t("JJMMAAAA")}
              value={selectedDate}
              onChange={handleDateChange}
            />
          </div>
          <div
            className='-containerflex'
            style={{ display: "flex", alignItems: "left" }}
          >
            <div className='fixed-width'>
              <input
                style={{
                  width: "170%",
                  height: "30px",
                  marginBottom: "30px",
                  borderRadius: "3px",
                  margin: "0",
                  border: "1px solid #3c6382",
                  borderColor: "#3c6382",
                  color: "#3c6382",
                }}
                type='text'
                placeholder={t("filtreDoc-Recherche")}
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              />
            </div>
            <div className='flex-container'>
              <div className='fixed-width'>
                <select
                  style={{
                    width: "60%",
                    height: "30px",
                    marginBottom: "30px",
                    marginLeft: "70px",
                    borderRadius: "3px",
                    borderColor: "#3c6382",
                    color: "#3c6382",
                  }}
                  value={sortByByName ? "name" : sortByById ? "id" : ""}
                  onChange={(e) => {
                    if (e.target.value === "name") {
                      setSortByByName(true);
                      setSortByById(false);
                    } else if (e.target.value === "id") {
                      setSortByByName(false);
                      setSortByById(true);
                    } else {
                      setSortByByName(false);
                      setSortByById(false);
                    }
                  }}
                >
                  <option value='name'>{t("filtreDoc-Nom")}</option>
                  <option value='id'>{t("filtreDoc-Id")}</option>
                </select>
              </div>
            </div>
          </div>
          <div className='flex-container'>
            <div className='fixed-width'>
              <select
                style={{
                  width: "100%",
                  height: "30px",
                  marginBottom: "30px",
                  borderRadius: "3px",
                  borderColor: "#3c6382",
                  color: "#3c6382",
                }}
                value={selectedStatus}
                onChange={handleStatusChange}
              >
                <option value='All'>{t("toutStatus")}</option>
                {uniqueStatuses.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {isLoading ? (
          <table>
            <tbody>
              <tr>
                <td colSpan='4'>{t("chargement")}</td>
              </tr>
            </tbody>
          </table>
        ) : !documents.length ? (
          <table>
            <tbody>
              <tr>
                <td colSpan='4'>{t("noData")}</td>
                <td style={{ width: "75px" }}>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={onBack}
                    style={{
                      cursor: "pointer",
                      fontSize: "16px",
                      marginRight: "auto",
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <>
            <table key={document.DocId} className='table  table-hover'>
              <thead>
                <tr>
                  <th style={{ width: "150px" }}>
                    {t("dateEntete")}
                    <button
                      onClick={toggleSortByDate}
                      style={{
                        marginLeft: "5px",
                        padding: "0",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {sortByDateAscending ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                    </button>
                  </th>
                  <th style={{ width: "450px" }}>
                    {t("nomDocumentEntete")}
                    <button
                      onClick={toggleSortByName}
                      style={{
                        marginLeft: "20px",
                        padding: "0",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {sortByNameAscending ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                    </button>
                  </th>

                  <th style={{ paddingRight: "40px" }}>
                    {t("statusEntete")}{" "}
                    <button
                      onClick={toggleSortByStatus}
                      style={{
                        marginLeft: "20px",
                        padding: "0",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {sortByNameAscending ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                    </button>
                  </th>
                </tr>
              </thead>
            </table>
            {currentDocuments.map((document) => (
              <table key={document.DocId} className='table  table-hover'>
                <tbody className=''>
                  <tr key={document.DocId}>
                    <td style={{ width: "120px" }}>{document.LastUpdate}</td>

                    <td style={{ padding: "4px", width: " 500px" }}>
                      <p style={{ fontSize: "16px", margin: 0 }}>
                        {document.FileName}
                      </p>
                      <p
                        style={{ fontSize: "10px", lineHeight: "1", margin: 0 }}
                      >
                        {document.DocId}
                      </p>
                    </td>
                    <td
                      className={getStatusClass(document.Status)}
                      style={{ width: "100px", color: "#fff" }}
                    >
                      {document.Status}
                    </td>
                    {shouldEnableDWLDJdata && (
                      <td style={{ width: "100px" }}>
                        <FontAwesomeIcon
                          icon={faDownload}
                          onClick={() =>
                            generateAndDownloadPDF(document.DocId, instanceId, document.ModelId, idToken)
                          }
                          style={{
                            cursor: "pointer",
                            fontSize: "16px",
                            marginRight: "auto",
                          }}
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            ))}{" "}
          </>
        )}
        <div className='pagination'>
        
          <button
            style={{ marginRight: " 10px" }}
            onClick={onBack}
            className='btn btn-danger'
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{
                cursor: "pointer",
                fontSize: "16px",
                marginRight: "auto",
              }}
            />{" "}{t("btnRetour" 
            )}          </button>
          {shouldEnableDWLDCSV && (
            <button className='btn btn-warning' style={{ marginRight: "10px" }}>
              <CSVLink
                data={generateCSVDataInstance(documents)}
                filename={`${instanceName}.csv`}
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{
                    cursor: "pointer",
                    fontSize: "16px",
                    marginRight: "auto",
                  }}
                />
                {"  "}
                {t("btnDWLDCSV")}
              </CSVLink>
            </button>
          )}
            <button
            style={{ marginRight: "10px" }}
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{
                cursor: "pointer",
                fontSize: "16px",
                marginRight: "auto",
              }}
            />
            {"  "}
            {t("btnPrecedent")}
          </button>
          {"   "}
          <button
            className='custom-button'
            onClick={() => paginate(currentPage + 1)}
            style={{ color: "#fff" }}
          >
            {t("btnSuivant")} {"  "}
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{
                cursor: "pointer",
                fontSize: "16px",
                marginRight: "auto",
              }}
            />
          </button>
        </div>
      </div>
    </>
  );
};

export default DocumentTable;
