import {Routes, Route} from 'react-router-dom'
import Login from '../Component/Login/Login'

export default function RouterDefault() {
  return (
    <Routes>
      
      <Route path="/*" element={<Login />} />
    </Routes>
    )
}
