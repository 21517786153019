import apiConfig from "../config/_config";// api.js


const apiURL = `${apiConfig}`;
//const token = localStorage.getItem("idToken");
    //localStorage.getItem("idToken"),
export async function createInstanceAndDocs(instanceName, modelId, exeptedDocs, file, fileName, json = "",token) {

  try { 
    const createInstanceResponse = await createInstance(instanceName, modelId, exeptedDocs,token);
    
    if (createInstanceResponse.statusCode === 200) {

      
      const createDocsResponse = await createDocs(createInstanceResponse.message.InstanceId, fileName, json,token);
      
      if (createDocsResponse.statusCode === 200) {
        
        const presignedUrl = createDocsResponse.message.PresignedURL;
        await uploadDoc(file, presignedUrl, token);
        
      }
    } 
  } catch (error) {
  }
}

export async function createInstance(instanceName, modelId,exeptedDocs,token){
    var instance = instanceName;
    var raw = JSON.stringify({
        "ModelId": modelId,
        "InstanceName" :instance,
        "ExpectedDocs": Number(exeptedDocs),
        "Options": {
          "OutputAs": "json"
        }
    })

    try {
        const response = await fetch(apiURL + '/process/create', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": token,
          },
            body: raw
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        
        return data;

      } catch (error) {
        throw new Error(error);
      }



}

async function createDocs(instanceId, fileName, json = "",token) {

    try {
      
        const response = await fetch(apiURL + '/document/s3upload', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": token,
          },
            body: JSON.stringify({
                "InstanceId": instanceId,  
                "FileName": fileName,
                "FileType": "application/pdf",
                "InputData": json

                
              })
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
      } catch (error) {
        throw new Error(error);
      }
    }


    async function uploadDoc(file, signedUrl,token) {
      var mypresignedurl=signedUrl;
      var requestOptions = {
          method: 'PUT',
          body: file,
            headers: {
              "Content-Type": "application/pdf",
              "Content-Length": file.length,
          }  
      };


      
        try {
            const response = await  fetch(mypresignedurl, requestOptions)
            ;
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
          } catch (error) {
            throw new Error( error);
          }
        }