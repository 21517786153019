/** @format */

import React, { useRef, useState, useEffect, useCallback } from "react";
import { createInstanceAndDocs } from "../../../API/UploadFileToS3";
import { getModelList } from "../../../API/ModelList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  faFile,
  faCheck,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import CodeMirror from "@uiw/react-codemirror";
import { dracula } from "@uiw/codemirror-theme-dracula";
import Cookies from "js-cookie";
import { json } from "@codemirror/lang-json";
export default function Import() {
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [instanceName, setInstanceName] = useState("");
  const [fileNumber, setFileNumber] = useState(null);
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);
  const [modelList, setModelList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("All");
  const [showJSONEditor, setShowJSONEditor] = useState(false);
  const { t } = useTranslation(); // Initialize the translation function
  const [value, setValue] = useState("");
  const idToken = Cookies.get('Token');

  let droppedFileName = "";
  
  useEffect(() => {
    getModelList(idToken)
      .then((data) => {
        setModelList(data.message.list);
      })
      .catch((error) => {
        console.error("Error fetching model list:", error);
      });
  }, [idToken]);
  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (e) => {
    const selectedFile = e.target.files[0];
    setSelectedFileName(selectedFile ? selectedFile.name : null);
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };
  const onChange = useCallback((val) => {
    JSON.stringify(val);
    setValue(val);
  }, []);
  const handleNext = () => {
    if (step === 1) {
      if (selectedModel === "All") {
        setError("Please select a model.");
      } else {
        setStep(2);
        setError("");
      }
    } else if (step === 2) {
      if (!instanceName) {
        setError("Please enter an instance name.");
      } else {
        setStep(3);
        setError("");
      }
    } else if (step === 3) {
      if (!fileNumber || isNaN(fileNumber)) {
        setError("Please enter a valid number of files.");
      } else {
        setStep(4);
        setError("");
      }
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const selectedFile = files[0];
      setSelectedFileName(selectedFile.name);

      droppedFileName = selectedFile.name;

    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileDelete = () => {
    setSelectedFileName(null);
  };

  const handleOpenJSONEditor = () => {
    setShowJSONEditor(true);
  };

  const handleCloseJSONEditor = () => {
    setShowJSONEditor(false);
  };
  const handleFileSubmit = async () => {

    
    if (!instanceName || !selectedModel || !fileNumber || !selectedFileName) {
      setError("Please fill in all the fields.");
      return;
    }

    if (
      fileNumber === 0 ||
      fileNumber === null ||
      fileNumber === undefined ||
      isNaN(fileNumber)
    ) {
      setError("Please enter a valid number of files");
      return;
    }

    try {

      if (instanceName) {
        let selectedFile = fileInputRef.current.files[0];

        if (!selectedFile) {
          setError("Please select a file");
          return;
        }
        await createInstanceAndDocs(
          instanceName,
          selectedModel,
          fileNumber,
          selectedFile,
          droppedFileName,
          value,
          idToken        ).then(() => {
          setStep(1);
          setInstanceName("");
          setFileNumber(null);
          setSelectedModel("All");
          selectedFile = null;
          droppedFileName = "";
        });
        setStep(1);
      } else {
        console.error("InstanceName is not defined");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showStep1 = step >= 1;
  const showStep2 = step >= 2;
  const showStep3 = step >= 3;
  const showStep4 = step >= 4;

  return (
    <>
      <div>
        <div
          className='container'
          style={{
            backgroundColor: "#f2f0f0",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "50px",
          }}
        >
          <div onDrop={handleDrop} onDragOver={handleDragOver}>
            <div>
              {showStep1 && (
                <div
                  className='card custom-card text-center'
                  style={{
                    width: "600px",
                    marginLeft: "350px",
                    borderRadius: "2px",
                    borderColor: "#3c6382",
                  }}
                >
                  <h4
                    style={{
                      color: "#3c6382",
                      marginTop: "20px",
                    }}
                  >
                    {t("choix-modele-ajout")} 
                  </h4>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <select
                      style={{
                        width: "300px",
                        height: "30px",
                        marginBottom: "30px",
                        borderRadius: "3px",
                        borderColor: "#3c6382",
                        color: "#3c6382",
                      }}
                      value={selectedModel}
                      onChange={handleModelChange}
                    >
                      <option value='All'>{t("choix-modele")}</option>{" "}
                      {/* Translate text */}
                      {modelList.map((model) => (
                        <option key={model.ModelId} value={model.ModelId}>
                          {model.ModelName}
                        </option>
                      ))}
                    </select>

                    <button
                      className='btn'
                      style={{
                        backgroundColor: "#5eb66e",
                        color: "#fff",
                        width: "150px",
                        marginBottom: "20px",
                      }}
                      onClick={handleNext}
                    >
                      {t("btnSuivant")}
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{
                          cursor: "pointer",
                          fontSize: "16px",
                          marginRight: "auto",
                        }}
                      />
                    </button>
                  </div>
                </div>
              )}
              {showStep2 && (
                <div
                  className='card custom-card text-center'
                  style={{
                    width: "600px",
                    marginLeft: "350px",
                    borderRadius: "2px",
                    borderColor: "#3c6382",
                  }}
                >
                  <h4
                    style={{
                      color: "#3c6382",
                      marginTop: "20px",
                    }}
                  >
                    {t("choix-instance-ajout")} 
                  </h4>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <input
                      style={{
                        width: "300px",
                        height: "30px",
                        marginBottom: "30px",
                        borderRadius: "3px",
                        borderColor: "#3c6382",
                        color: "#3c6382",
                      }}
                      type='text'
                      className='form-control mb-2'
                      placeholder={t("choix-instance")}
                      value={instanceName}
                      onChange={(e) => setInstanceName(e.target.value)}
                    />
                    <button
                      className='btn'
                      style={{
                        backgroundColor: "#5eb66e",
                        color: "#fff",
                        width: "150px",
                        marginBottom: "20px",
                      }}
                      onClick={handleNext}
                    >
                      {t("btnSuivant")}
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{
                          cursor: "pointer",
                          fontSize: "16px",
                          marginRight: "auto",
                        }}
                      />
                    </button>
                  </div>
                </div>
              )}
              {showStep3 && (
                <div
                  className='card custom-card text-center'
                  style={{
                    width: "600px",
                    marginLeft: "350px",
                    borderRadius: "2px",
                    borderColor: "#3c6382",
                  }}
                >
                  <h4
                    style={{
                      color: "#3c6382",
                      marginTop: "20px",
                    }}
                  >
                    {t("choix-nb-fichier-ajout")}
                  </h4>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <input
                      style={{
                        width: "300px",
                        height: "30px",
                        marginBottom: "30px",
                        borderRadius: "3px",
                        borderColor: "#3c6382",
                        color: "#3c6382",
                      }}
                      type='text'
                      className='form-control mb-2'
                      placeholder={t("choix-nb-fichier")}
                      value={fileNumber}
                      onChange={(e) => setFileNumber(e.target.value)}
                    />
                    <button
                      style={{
                        backgroundColor: "#5eb66e",
                        color: "#fff",
                        width: "150px",
                        marginBottom: "20px",
                      }}
                      className='btn btn-primary'
                      onClick={handleNext}
                    >
                      {t("btnSuivant")}
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{
                          cursor: "pointer",
                          fontSize: "16px",
                          marginRight: "auto",
                        }}
                      />
                    </button>
                  </div>
                </div>
              )}
              {showStep4 && (
                <div>
                  <div
                    className='card custom-card text-center'
                    style={{
                      width: "600px",
                      marginLeft: "350px",
                      borderRadius: "2px",
                      borderColor: "#3c6382",
                    }}
                  >
                    <h4
                      style={{
                        color: "#3c6382",
                        marginTop: "20px",
                      }}
                    >
                      {t("choix-fichier-ajout")} 
                    </h4>
                    <br />
                    <p
                      style={{
                        color: "#3c6382",
                        size: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      {t("choix-fichier-choisi")} : {selectedFileName}
                    </p>
                    <input
                      type='file'
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileUpload}
                    />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        className='btn btn-primary'
                        onClick={handleFileSelect}
                        style={{ marginRight: "10px" }}
                      >
                        <FontAwesomeIcon icon={faFile} /> {t("choix-fichier")}{" "}
                      </button>
                      <button
                        className='btn btn-success'
                        onClick={handleFileSubmit}
                        style={{ marginRight: "10px" }}
                      >
                        <FontAwesomeIcon icon={faCheck} />{" "}
                        {t("choix-fichier-upload")} 
                      </button>
                      <button
                        className='btn btn-danger'
                        onClick={handleFileDelete}
                      >
                        <FontAwesomeIcon icon={faTrash} />{" "}
                        {t("choix-fichier-suppr")}
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        className='btn btn-warning'
                        onClick={handleOpenJSONEditor}
                      >
                        <FontAwesomeIcon icon={faPlus} />{" "}
                        {t("choix-fichier-ajoutJson")}
                      </button>
                    </div>
                  </div>
                  <div style={{ display: showJSONEditor ? "block" : "none" }}>
                    <h4 style={{ color: "#3c6382", marginTop: "20px" }}>
                      {t("choix-fichier-ajoutJson")} 
                    </h4>
                    <div
                      style={{
                        width: "400px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <CodeMirror
                        value={value}
                        height='700px'
                        theme={dracula}
                        extensions={[json({ jsx: true })]}
                        onChange={onChange}
                      />
                    </div>
                    <button
                      className='btn btn-primary'
                      onClick={handleCloseJSONEditor}
                      style={{ marginTop: "10px" }}
                    >
                      {t("choix-fichier-hideJSON")} 
                    </button>
                  </div>
                </div>
              )}
              {error && <p className='text-danger'>{error}</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
