/** @format */
import apiConfig from "../config/_config";// api.js


export async function getInstance(token) {
  const apiUrl =`${apiConfig}/process/list`;
 
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: token,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {

    throw new Error(error);
  }
}
