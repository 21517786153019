/** @format */

import { Routes, Route } from "react-router-dom";
import DashBoard from "../Component/Dashboard";
import Stats from "../Component/Dashboard/Stats/Stats";
import NavBar from "../Component/Dashboard/NavBar/NavBar";
import Import from "../Component/Dashboard/Import/Import";
import process from "process";
let userRole = localStorage.getItem("role");
const shouldRenderImportRoute = process.env.REACT_APP_IMPORT_APP === "true" || userRole === process.env.REACT_APP_ROLE_ADMIN;
const shouldRenderDashRoute = process.env.REACT_APP_DASHBOARD_APP === "true" || userRole === process.env.REACT_APP_ROLE_ADMIN;
const shouldRenderStatsRoute = process.env.REACT_APP_STATS_APP === "true" || userRole === process.env.REACT_APP_ROLE_ADMIN;
export default function RouterUser() {
  return (
    <>
      <NavBar />
      <Routes>
      {shouldRenderDashRoute ? (
          <Route
            path='/*'
            element={
              <>
                <DashBoard />
              </>
            }
          />
        ) : (
          <Route
            path='/*'
            element={
              <>
                <p>DashBoard en maintenance</p>
              </>
            }
          />
        )}
      { shouldRenderStatsRoute && (
        <Route
          path='/Stats'
          element={
            <>
              <Stats />
            </>
          }
        />)}
        {shouldRenderImportRoute && (
          <Route path='/import' element={<Import />} />
        )}{" "}
      </Routes>
    </>
  );
}
