/** @format */

import { useState } from "react";
import Tableau from "./TableauInstance";
import DocumentTable from "./TableauDocs";

const SwitchTableau = (modelList) => {
  const [currentInstance, setCurrentInstance] = useState(null);
  const [currentInstanceName, setCurrentInstanceName] = useState(null);

  const handleInstanceSelected = (instanceId) => {
    setCurrentInstance(instanceId);
  };
  const handleInstanceNameSelected = (instanceName) => {
    setCurrentInstanceName(instanceName);
  };
  const handleBackClick = () => {
    setCurrentInstance(null);
  };

  return (
    <div style={{ textAlign :  'center'}}>
      {currentInstance === null ? (
        <Tableau onInstanceSelected={handleInstanceSelected} onInstanceNameSelected={handleInstanceNameSelected} modelList={modelList}/>
      ) : (
        <DocumentTable instanceId={currentInstance} instanceName={currentInstanceName} onBack={handleBackClick} />
      )}
    </div>
  );
};

export default SwitchTableau;
