/** @format */

import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

const StatusStats = ({ documents }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current && documents && documents.length > 0) {
      const statusCounts = {};

      documents.forEach((document) => {
        const status = document.Status;
        statusCounts[status] = (statusCounts[status] || 0) + 1;
      });

      const statuses = Object.keys(statusCounts);
      const counts = Object.values(statusCounts);

      if (!chartRef.current.chart) {
        chartRef.current.chart = new Chart(chartRef.current, {
          type: "pie",
          data: {
            labels: statuses,
            datasets: [
              {
                data: counts,
                backgroundColor: [
                  "rgb(255, 99, 132)",
                  "rgb(255, 205, 86)",
                  "rgb(54, 162, 235)",
                  "rgb(75, 192, 192)",
                  "rgb(153, 102, 255)",
                  "rgb(255, 159, 64)",
                ],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            width: 200,
            height: 200,
          },
        });
      } else {
        chartRef.current.chart.data.labels = statuses;
        chartRef.current.chart.data.datasets[0].data = counts;
        chartRef.current.chart.update();
      }
    }
  }, [documents]);

  if (!documents || documents.length === 0) {
    return (
      <div>
        <p className='text-danger'>
         
        </p>
      </div>
    );
  }

  return (
    <div>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default StatusStats;
