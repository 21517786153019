/** @format */

import { useEffect, useState, useContext } from "react";
import { getInstance } from "../../../API/Instance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { getModelList } from "../../../API/ModelList";
import {
  faArrowLeft,
  faArrowRight,
  faSortUp,
  faSortDown,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import "./tableau.css";
import { FiltersContext } from "../../../Context/main";
import { useTranslation } from "react-i18next";
import * as configStatus from "../../../config/config.status";
import process from "process";
import Cookies from "js-cookie";
const Tableau = ({ onInstanceSelected, onInstanceNameSelected }) => {
  const [instances, setInstances] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [modelList, setModelList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("All");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [sortByByName, setSortByByName] = useState(true);
  const [sortByById, setSortByById] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const { filters, setFilters } = useContext(FiltersContext);
  const [sortByDateAscending, setSortByDateAscending] = useState(false);
  const [sortByNameAscending, setSortByNameAscending] = useState(false);
  const [sortByStatusAscending, setSortByStatusAscending] = useState(false);
  const instancesPerPage = 10;
  const userRole = localStorage.getItem("role");
  const showInstances = process.env.REACT_APP_DASHBOARD_INSTANCES === "true" || userRole === process.env.REACT_APP_ROLE_ADMIN;
  const showDocsSearch = process.env.REACT_APP_DASHBOARD_DOCS === "true" || userRole === process.env.REACT_APP_ROLE_ADMIN;
  const showFilters = process.env.REACT_APP_DASHBOARD_FILTERS === "true" || userRole === process.env.REACT_APP_ROLE_ADMIN;
  const idToken = Cookies.get('Token');
  const { t } = useTranslation();
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getInstance(idToken);
        const instances = response.message.list.map((instance) => ({
          ...instance,
          LastUpdate: formatDate(instance.LastUpdate),
        }));
        const statuses = [
          ...new Set(instances.map((instance) => instance.Status)),
        ];
        setUniqueStatuses(statuses);

        setInstances(instances);
        setIsLoading(false);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des données de l'API",
          error
        );
        setIsLoading(false);
      }
    }

    fetchData();

    if (idToken) {
      getModelList(idToken)
        .then((data) => {
          setModelList(data.message.list);
        })
        .catch((error) => {
          console.error("Error fetching model list:", error);
        });
    } else {
    }
  }, [idToken]);

  const applyFilters = (data) => {
    const filteredData = data.filter((instance) => {
      const modelMatch =
        selectedModel === "All" ||
        modelList.find((model) => model.ModelId === instance.ModelId)
          ?.ModelName === selectedModel;

      const dateMatch =
        !selectedDate || instance.LastUpdate.includes(selectedDate);

      const statusMatch =
        selectedStatus === "All" || instance.Status === selectedStatus;

      const nameA = instance.InstanceName
        ? instance.InstanceName.toLowerCase()
        : "";
      const nameB = filterValue ? filterValue.toLowerCase() : "";

      const idA = instance.InstanceId ? instance.InstanceId.toLowerCase() : "";
      const idB = filterValue ? filterValue.toLowerCase() : "";

      let nameFilterMatch = false;
      let idFilterMatch = false;

      if (sortByByName) {
        nameFilterMatch = nameA.includes(nameB);
      } else if (sortByById) {
        idFilterMatch = idA.startsWith(idB) || idA === idB;
      }

      return (
        (modelMatch || !selectedModel) &&
        (dateMatch || !selectedDate) &&
        (statusMatch || selectedStatus === "All") &&
        ((sortByByName && nameFilterMatch) ||
          (sortByById && idFilterMatch) ||
          (!filterValue && !sortByByName && !sortByById))
      );
    });

    return filteredData;
  };

  const handleCopyClick = (instanceId) => {

    const instanceToCopy = instances.find(
      (instance) => instance.InstanceId === instanceId
    );
    const textToCopy = instanceToCopy.InstanceId;

    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = textToCopy;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextarea);
  };
  const paginateInstances = (data, pageNumber) => {
    const startIndex = (pageNumber - 1) * instancesPerPage;
    const endIndex = startIndex + instancesPerPage;
    return data.slice(startIndex, endIndex);
  };

  const sortedData = applyFilters(instances).sort((a, b) => {
    let comparisonResult = 0;

    if (sortByStatusAscending) {
      const statusComparison = a.Status.localeCompare(b.Status);
      if (statusComparison !== 0) {
        comparisonResult = statusComparison;
      }
    }

    if (comparisonResult === 0) {
      if (sortByDateAscending) {
        // Tri par date croissante
        const dateA = new Date(
          parseInt(a.LastUpdate.substr(6, 4)),
          parseInt(a.LastUpdate.substr(3, 2)) - 1,
          parseInt(a.LastUpdate.substr(0, 2))
        );
        const dateB = new Date(
          parseInt(b.LastUpdate.substr(6, 4)),
          parseInt(b.LastUpdate.substr(3, 2)) - 1,
          parseInt(b.LastUpdate.substr(0, 2))
        );

        comparisonResult = dateA - dateB;
      } else {
        // Tri par date décroissante
        const dateA = new Date(
          parseInt(a.LastUpdate.substr(6, 4)),
          parseInt(a.LastUpdate.substr(3, 2)) - 1,
          parseInt(a.LastUpdate.substr(0, 2))
        );
        const dateB = new Date(
          parseInt(b.LastUpdate.substr(6, 4)),
          parseInt(b.LastUpdate.substr(3, 2)) - 1,
          parseInt(b.LastUpdate.substr(0, 2))
        );

        comparisonResult = dateB - dateA;
      }

      if (comparisonResult === 0 && !sortByNameAscending && !sortByById) {
        const nameA = a.InstanceName ? a.InstanceName.toLowerCase() : "";
        const nameB = b.InstanceName ? b.InstanceName.toLowerCase() : "";

        comparisonResult = nameA.localeCompare(nameB);
      }
    }

    return comparisonResult;
  });
  const paginatedData = paginateInstances(sortedData, currentPage);

  const paginate = (pageNumber) => {
    const nextPageIndex = pageNumber - 1;
    const startIndex = nextPageIndex * instancesPerPage;
    const endIndex = startIndex + instancesPerPage;
    const hasNextPageData = instances.slice(startIndex, endIndex).length > 0;

    if (hasNextPageData) {
      setCurrentPage(pageNumber);
    }
  };

  const handleModelChange = (e) => {
    const selectedModel = e.target.value;
    setSelectedModel(selectedModel);
    setFilters({ ...filters, selectedModel });
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedDate(selectedDate);
    setFilters({ ...filters, selectedDate });
  };

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setSelectedStatus(selectedStatus);
    setFilters({ ...filters, selectedStatus });
  };

  const toggleSortByDate = () => {
    setSortByDateAscending(!sortByDateAscending);
  };
  const toggleSortByName = () => {
    setSortByNameAscending(!sortByNameAscending);
  };

  const toggleSortByStatus = () => {
    setSortByStatusAscending(!sortByStatusAscending);
  };

  const getStatusClass = (status) => {
    const statusMappings = configStatus.statusInstanceMappings;

    if (statusMappings.hasOwnProperty(status)) {
      return statusMappings[status];
    } else {
      return "bg-info";
    }
  };
  const formatDate = (dateStr) => {
    const year = dateStr.substr(0, 4);
    const month = dateStr.substr(4, 2);
    const day = dateStr.substr(6, 2);
    return `${day}/${month}/${year}`;
  };

  const hasNextPage = paginatedData.length > 0;

  return (
    <>
      <div className='header'>
        <h3>{t("operations")}</h3>
      </div>
      <div className='content'>
        <div>
          <p>{t("text-operations-P1")}</p>
          <p>{t("text-operations-P2")}</p>
        </div>
      </div>



      { showInstances && (


      <div className='table-container' style={{ minHeight: "400px" }}>
        <div className='flex-container'>
          <div className='fixed-width'>


          { showFilters && (
            
            <input
              style={{
                boxSizing: "border-box",
                width: "100%",
                height: "30px",
                marginBottom: "10px",
                borderRadius: "3px",
                border: "1px solid #3c6382",
                borderColor: "#3c6382",
                color: "#3c6382",
                margin: "0",
              }}
              type='text'
              placeholder={t("JJMMAAAA")}
              value={selectedDate}
              onChange={handleDateChange}
            />)}
          </div>
          <div
            className='-containerflex'
            style={{ display: "flex", alignItems: "left" }}
          >
            {showFilters && (
            <div className='fixed-width'>
              <input
                style={{
                  width: "100%",
                  height: "30px",
                  marginBottom: "30px",
                  borderRadius: "3px",
                  margin: "0",
                  border: "1px solid #3c6382",
                  borderColor: "#3c6382",
                  color: "#3c6382",
                }}
                type='text'
                placeholder={t("filtreInstance-Recherche")}
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              />
            </div> )}
            {showFilters && (
            <div className='flex-container'>
              <div className='fixed-width'>
                <select
                  style={{
                    width: "100%",
                    height: "30px",
                    marginBottom: "30px",
                    borderRadius: "3px",
                    borderColor: "#3c6382",
                    color: "#3c6382",
                  }}
                  value={sortByByName ? "name" : sortByById ? "id" : ""}
                  onChange={(e) => {
                    if (e.target.value === "name") {
                      setSortByByName(true);
                      setSortByById(false);
                    } else if (e.target.value === "id") {
                      setSortByByName(false);
                      setSortByById(true);
                    } else {
                      setSortByByName(false);
                      setSortByById(false);
                    }
                  }}
                >
                  <option value='name'>{t("filtreInstance-Nom")}</option>
                  <option value='id'>{t("filtreInstance-Id")}</option>
                </select>
              </div>
            </div>)}
     
          </div> 
          { showFilters && (
          <div className='fixed-width' style={{ marginRight: "10px" }}>
            <select
              style={{
                width: "100%",
                height: "30px",
                marginBottom: "10px",
                borderRadius: "3px",
                borderColor: "#3c6382",
                color: "#3c6382",
              }}
              value={selectedModel}
              onChange={handleModelChange}
            >
              <option value='All'>{t("toutModele")}</option>
              {modelList.map((model) => (
                <option key={model.ModelId} value={model.ModelName}>
                  {model.ModelName}
                </option>
              ))}
            </select>
          </div>)}
          { showFilters && (

          <div className='flex-container'>
            <div className='fixed-width'>
              <select
                style={{
                  width: "100%",
                  height: "30px",
                  marginBottom: "30px",
                  borderRadius: "3px",
                  borderColor: "#3c6382",
                  color: "#3c6382",
                }}
                value={selectedStatus}
                onChange={handleStatusChange}
              >
                <option value='All'>{t("toutStatus")}</option>
                {uniqueStatuses.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
          </div>)}
        </div>   

        {isLoading ? (
          <table>
            <tbody>
              <tr>
                <td colSpan='7'>{t("chargement")}</td>
              </tr>
            </tbody>
          </table>
        ) : paginatedData.length === 0 ? (
          <table>
            <tbody>
              <tr>
                <td colSpan='7'>{t("noData")}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <>
            <table className='table'>
              <thead>
                <tr>
                  <th style={{ width: "150px" }}>
                    {t("dateEntete")}
                    <button
                      onClick={toggleSortByDate}
                      style={{
                        marginLeft: "5px",
                        padding: "0",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {sortByDateAscending ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                    </button>
                  </th>
                  <th style={{ width: "450px" }}>
                    {t("nomInstanceEntete")}
                    <button
                      onClick={toggleSortByName}
                      style={{
                        marginLeft: "5px",
                        padding: "0",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {sortByNameAscending ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                    </button>
                  </th>
                  <th style={{ width: "50px" }}>{t('Attendu')}</th>
                  <th style={{ width: "50px" }}>{t('Uploadé')}</th>
                  <th style={{ width: "50px" }}>{t("Crée")}</th>
                  <th style={{ width: "50px" }}>{t("Completed")}</th>

                  <th style={{ maxWidth: "40px", paddingLeft: "40px" }}>
                    {t("modeleEntete")}
                  </th>
                  <th style={{ paddingRight: "40px" }}>
                    {t("statusEntete")}
                    <button
                      onClick={toggleSortByStatus}
                      style={{
                        marginLeft: "5px",
                        padding: "0",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {sortByStatusAscending ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                    </button>
                  </th>
                </tr>
              </thead>
            </table>
            {paginatedData.map((instance, index) => (
              <table key={instance.InstanceId} className='table table-hover'>
                <tbody>
                  <tr key={instance.InstanceId}>
                    <td style={{ width: "120px" }}>{instance.LastUpdate}</td>

                    <td
                      style={{
                        padding: "4px",
                        width: "500px",
                        position: "relative",
                        textAlign: "center",
                      }}
                    >
                      {instance.InstanceName ? (
                        <div
                          style={{
                            fontSize: "12px",
                            overflowX:
                              instance.InstanceName.length > 20
                                ? "auto"
                                : "visible",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {instance.InstanceName}
                        </div>
                      ) : (
                        "..."
                      )}
                      <FontAwesomeIcon
                        icon={faCopy}
                        onClick={() => handleCopyClick(instance.InstanceId)}
                        style={{
                          cursor: "pointer",
                          fontSize: "16px",
                          position: "absolute",
                          top: "4px",
                          right: "10px",
                        }}
                      />
                    </td>
                    <td style={{ width: "80px" }}>{instance.ExpectedDocs}</td>
                    <td
                      style={{
                        width: "80px",
                        color:
                          instance.UploadedDocs < instance.ExpectedDocs
                            ? "red"
                            : "inherit",
                      }}
                    >
                      {instance.UploadedDocs}
                    </td>
                    <td style={{ width: "80px" }}>{instance.CreatedDocs}</td>
                    <td
                      style={{
                        width: "80px",
                        color:
                          instance.CompletedDocs >= instance.ExpectedDocs
                            ? "green"
                            : "inherit",
                      }}
                    >
                      {instance.CompletedDocs}
                    </td>
                    <td>
                      {modelList.find(
                        (model) => model.ModelId === instance.ModelId
                      )?.ModelName || instance.ModelId}
                    </td>
                    <td
                      style={{ width: "100px", color: "#fff" }}
                      className={getStatusClass(instance.Status)}
                    >
                      {instance.Status}
                    </td>
                    
                    { showDocsSearch && (

                    <td style={{ width: "75px" }}>
                      <FontAwesomeIcon
                        icon={faSearch}
                        onClick={() => {
                          if (instance.NbDocs !== 0) {
                            onInstanceSelected(instance.InstanceId);
                            onInstanceNameSelected(instance.InstanceName);
                          }
                        }}
                        style={{
                          cursor:
                            instance.CreatedDocs === 0
                              ? "not-allowed"
                              : "pointer",
                          fontSize: "16px",
                          marginRight: "auto",
                          color:
                            instance.CreatedDocs === 0 ? "gray" : "inherit",
                        }}
                      />
                    </td>)}
                  </tr>
                </tbody>
              </table>
            ))}
          </>
        )}
        <div className='pagination'>
          <button
            style={{ marginRight: "10px" }}
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{
                cursor: "pointer",
                fontSize: "16px",
                marginRight: "auto",
              }}
            />
            {"  "}
            {t("btnPrecedent")}
          </button>
          {"   "}
          <button
            className='custom-button'
            onClick={() => paginate(currentPage + 1)}
            disabled={!hasNextPage}
            style={{ color: "#fff" }}
          >
            {t("btnSuivant")} {"  "}
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{
                cursor: "pointer",
                fontSize: "16px",
                marginRight: "auto",
              }}
            />
          </button>
        </div>
      </div>)}
    </>
  );
};

export default Tableau;
