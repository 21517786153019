/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useState } from "react";
import "./Login.css";
import mainlogo from "../../IMG/media/LOGOTYPES/MyActivHub/Web/PNG/logoType.png";
import { loginUser } from "../../API/Auth";
import { useNavigate } from "react-router-dom";
import backgroundIMG from "./../../IMG/media/ICONOGRAPHIE/IMAGES/background.jpg";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addToStore } from "../../Store/CartSlice";
export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const { t } = useTranslation();
  const dispach = useDispatch();


  const navigate = useNavigate();
  const handleLogin = (e) => {
    e.preventDefault(); 

    if (
      !username ||
      !password ||
      username === "" ||
      password === "" ||
      username === undefined ||
      password === undefined
    ) {
      setErrorMessage("Please enter both username and password.");
    } else {
      loginUser(username, password)
        .then((data) => {
          dispach(addToStore({token : data.idToken,
         } ));
          const token = data.idToken;
          if (token) {
            navigate("/User/");
          } else {
            setErrorMessage(JSON.stringify(data));
          }
        })
        .catch((error) => {
          console.error(error);
          setErrorMessage("An error occurred while logging in.");
        });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundImage: `url(${backgroundIMG})`, 
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat" }}
    >
      <div
        className='text-center'
        style={{
          width: "18rem",
          margin: "auto",
          marginTop: "120px",
        }}
      >
        <div className=''>
          <img
            src={mainlogo}
            alt='logo'
            style={{ width: "240px", height: "auto" }}
          />
        </div>
        <br />
        <br></br>
        <div>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <p
              htmlFor='password'
              className='d-flex justify-content-start'
              style={{ alignSelf: "start", margin: 0, fontSize: "14px" }}
            >
              Email <span className='text-danger'>*</span>
            </p>
            <div className='form-group'>
              <input
                type='text'
                id='username'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={{ width: "320px", borderRadius: "50px" }}
                className='form-control input-common-style'
              />
            </div>
            <br />
            <p
              htmlFor='password'
              className='d-flex justify-content-start'
              style={{ alignSelf: "start", margin: 0, fontSize: "14px" }}
            >
              {t('motdepasse')} <span className='text-danger'>*</span>
            </p>
            <div className='form-group'>
              <label style={{ position: "relative" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  id='password'
                  className='form-control input-common-style' 
                  name='password'
                  style={{ width: "320px", borderRadius: "50px" }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className='password-icon'>
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    onClick={togglePasswordVisibility}
                    style={{
                      fontSize: "20px",
                      color: "#868686",
                      position: "absolute",
                      top: "50%",
                      right: "20px",
                      transform: "translateY(-50%)",
                      transition: "color 0.2s",
                    }}
                  />
                </div>
              </label>
            </div>
            <br></br>
            <span
              onClick={handlePasswordChange}
              style={{
                cursor: "pointer",
                textDecoration: "none", 
                color: "black", 
                transition: "color 0.2s, text-decoration 0.2s",
                alignSelf: "flex-start",
                fontSize: "14px",
              }}
              onMouseEnter={(e) => {
                e.target.style.color = "blue"; 
                e.target.style.textDecoration = "underline"; 
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "black"; 
                e.target.style.textDecoration = "none"; 
              }}
            >
              {t('mdpoublie')} 
            </span>
            <br />
            

            <hr style={{ width: "100%", margin: "20px 0" }} />

            <Link
              to='/User/'
              onClick={handleLogin}
              className='btn btn-primary btn-block'
              style={{
                width: "250px",
                marginTop: "15px", 
                borderRadius: "10px",
              }}
            >
              {t("connexion")} 
            </Link>
          </form>

          {errorMessage && (
            <div className='alert alert-danger mt-3' role='alert'>
              <strong>Error:</strong> {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
          
  );
}
